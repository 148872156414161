let default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
let resize_width = window.innerWidth;
const break_point = 600;
$(function() {
	let default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});

  /*------------------------*/
  //ページトップ
  /*------------------------*/
	const topBtn = $('.pageTop');
  topBtn.hide();
	// スクロールが200に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) {
				topBtn.fadeIn();
		} else if ($(this).scrollTop() < 200) {
				topBtn.fadeOut();
		} else {
      topBtn.fadeIn();
    }
	});
	// スクロールしてトップ
	topBtn.on('click',function () {
		$('body,html').animate({
				scrollTop: 0
		}, 500);
		return false;
  });

  //スムーススクロール（ページ内リンク）
  $('a[href^="#"],.js-scroll').on("click", function () {
    const f = 600;
    let e = $(this).attr("href");
    let g = $(e == "#" || e == "" ? "html" : e);
    let d = g.offset().top;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  let getDevice = (function() {
  let ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
    }
  })();

	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	let timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
			}
		}, 200);
	});
});

/*------------------------*/
//関数
/*------------------------*/
/*トップページ　スライダー*/
let sliderMv = function() {
	$(function() {
		//slider設置
    const $slider = $('.slider--mv');
    let slideCount = $slider.children().length;
    if (slideCount >= 2) {
      $slider.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: false,
        dots: true,
        slidesToShow: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 600,//ブレイクポイントを指定
            settings: {
              draggable: true,
              arrows: true
            }
          }
        ]
      });
    }
	});
}